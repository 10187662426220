import React, { PropsWithChildren, useEffect, useState } from "react";
import * as Sentry from "@sentry/nextjs";
import { useTranslations } from "next-intl";

import { useUser } from "modules/user";
import { useToaster } from "modules/Toaster";
import { addProductsToCart, Cart, getCart, removeProductFromCart } from "resources/AudiotekaApi";

import { trackAddToCartEvent } from "./cart.tracking";
import { CartModal } from "./cart-modal";
import { cartConfig } from "./cart.config";
import { cartContext } from "./cart.context";
import { cartStore } from "./cart.store";
import { CartContext, CartItem } from "./cart.types";

export function CartProvider({ children }: PropsWithChildren) {
  const user = useUser();
  const { showToast } = useToaster();
  const t = useTranslations();

  const [recentItem, setRecentItem] = useState<CartContext["recentItem"]>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [cartData, setCartData] = useState<{ items: CartItem[]; currency: string; value: number }>({
    items: [],
    currency: cartConfig.defaultCurrency,
    value: 0,
  });

  const updateStateFromCart = (newCart: Cart) => {
    const { cart_products: products = [] } = newCart || {};

    const items = products.map((product) => ({
      audiobook: newCart._embedded["app:audiobooks"].find((audiobook) => audiobook.id === product.id),
      details: product,
    }));
    const currency = newCart?.currency || cartConfig.defaultCurrency;
    const value = newCart?.total_price || 0;

    setCartData({ items, currency, value });

    return newCart;
  };

  const updateCartForLoggedIn = async (storedCart: Cart) => {
    if (storedCart.cart_products.length > 0) {
      const mergedCartResponse = await addProductsToCart(storedCart.cart_products.reverse().map(({ id }) => id));

      await cartStore.clearCart();

      return updateStateFromCart(mergedCartResponse);
    }

    const cartResponse = await getCart();

    return updateStateFromCart(cartResponse);
  };

  const updateCart = async (): Promise<Cart> => {
    const storedCart = await cartStore.getCart();

    if (user.isLoggedIn) {
      return updateStateFromCart(await updateCartForLoggedIn(storedCart));
    }

    return updateStateFromCart(storedCart);
  };

  const addToCart: CartContext["addToCart"] = async (product) => {
    if (cartData.items.length >= cartConfig.itemsLimit) {
      showToast({
        type: "error",
        title: t("cart.limit_reached.title"),
        message: t("cart.limit_reached.message", { limit: cartConfig.itemsLimit }),
      });
      return;
    }

    setIsLoading(true);

    try {
      const newCart = user.isLoggedIn ? await addProductsToCart([product.id]) : await cartStore.addToCart(product);

      updateStateFromCart(newCart);

      const cartItemIndex = newCart.cart_products.findIndex(({ id }) => id === product.id);
      const cartItemDetails = newCart.cart_products[cartItemIndex];

      trackAddToCartEvent({ cart: newCart, cartItemIndex, cartItemDetails, product });

      setRecentItem({ product, price: cartItemDetails.final_user_price });
    } catch (error) {
      Sentry.captureException(error);
    }

    setIsLoading(false);
  };

  const removeFromCart = async (id: string) => {
    setIsLoading(true);

    const newCart = user.isLoggedIn ? await removeProductFromCart(id) : await cartStore.removeFromCart(id);

    updateStateFromCart(newCart);
    setIsLoading(false);
  };

  useEffect(() => {
    async function updateCartPerUserId() {
      await updateCart();
      setIsLoading(false);
    }

    updateCartPerUserId();
  }, [user?.id]);

  return (
    <cartContext.Provider
      value={{
        items: cartData.items,
        currency: cartData.currency,
        recentItem,
        value: cartData.value,
        isLoading,
        addToCart,
        removeFromCart,
      }}
    >
      {recentItem && <CartModal cartItem={recentItem} onClose={() => setRecentItem(null)} />}
      {children}
    </cartContext.Provider>
  );
}
