import React, { PureComponent } from "react";
import gtm from "react-gtm-module";
import { useLocale } from "next-intl";

import { useUser } from "modules/user";
import { getPrices } from "modules/Audiobook/utils";
import api from "api-web-client";
import { useLaunchTestDomain } from "utils/useLaunchTestDomain";

import { CartModal } from "./cart-modal";
import { cartConfig } from "./cart.config";
import { cartContext } from "./cart.context";
import { CartContext } from "./cart.types";

type Props = {
  user: ReturnType<typeof useUser>;
  children: React.ReactNode;
  locale: string;
  launchTestDomain: string;
};
type State = Omit<CartContext, "addToCart" | "removeFromCart">;

class OldCartProviderBase extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      recentItem: null,
      currency: cartConfig.defaultCurrency,
      isLoading: false,
      items: [],
      value: 0,
    };
  }

  public componentDidMount() {
    this._refreshCounter();

    api.getVisitor(this.props.locale).then((visitor) => this.setState({ currency: visitor.data.currency }));
  }

  public componentDidUpdate(prevProps) {
    if (prevProps.user.id !== this.props.user.id || prevProps.launchTestDomain !== this.props.launchTestDomain) {
      this._refreshCounter();
    }
  }

  public get language(): string {
    return this.props.locale;
  }

  private _clearAddedItem = () => {
    this.setState({ recentItem: null });
  };

  private _refreshCounter = async () => {
    try {
      const response = await fetch(
        `${this.props.launchTestDomain}/${this.language}/user/profile.json?mode=cart&v=${Date.now()}`,
        { credentials: "include" }
      );
      const { cart } = await response.json();

      this.setState({ items: cart.products });

      return cart;
    } catch {
      this.setState({ items: [] });

      return null;
    }
  };

  public addToCart: CartContext["addToCart"] = async (product, licenses) => {
    if (!product.reference_id) {
      // Reference id is required to add product to cart

      return;
    }

    const { user } = this.props;

    this.setState({ isLoading: true });

    const referenceIdWithoutCatalog = product.reference_id.replace(/[a-z]{2}_/, "");

    const request = await fetch(
      `${this.props.launchTestDomain}/${this.language}/cart/add/${referenceIdWithoutCatalog}`,
      { credentials: "include" }
    );

    if (request.ok) {
      const price = getPrices(
        {
          price: product.price,
          price_for_subscribers: product.price_for_subscribers,
          discount_price: product.discount_price,
          lowest_price: product.lowest_price,
        },
        licenses,
        user.isClubMember
      );

      const cart = await this._refreshCounter();

      gtm.dataLayer({
        dataLayer: {
          event: "add_to_cart",
          ecommerce: {
            currency: this.state.currency,
            value: (cart.products || []).map((item) => item.price).reduce((a, b) => a + b),
            items: [
              {
                coupon: cart.promocode,
                discount: price.base - price.final,
                index: 0,
                item_id: product.id,
                item_name: product.name,
                item_variant: "one-off",
                price: price.final,
                quantity: 1,
              },
            ],
          },
        },
      });

      this.setState({
        recentItem: { product, price: price.final * 100 },
        isLoading: false,
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  // eslint-disable-next-line class-methods-use-this
  public removeFromCart = () => {
    // Not implemented in old cart but required in context
  };

  public render() {
    return (
      <cartContext.Provider value={{ ...this.state, addToCart: this.addToCart, removeFromCart: this.removeFromCart }}>
        {this.state.recentItem && <CartModal cartItem={this.state.recentItem} onClose={this._clearAddedItem} />}
        {this.props.children}
      </cartContext.Provider>
    );
  }
}

export const OldCartProvider = ({ children }: { children: React.ReactNode }) => {
  const user = useUser();
  const locale = useLocale();
  const launchTestDomain = useLaunchTestDomain();

  return (
    <OldCartProviderBase user={user} locale={locale} launchTestDomain={launchTestDomain}>
      {children}
    </OldCartProviderBase>
  );
};
