import React from "react";
import dynamic from "next/dynamic";
import { useLocale } from "next-intl";

import { useUser } from "modules/user";
import { User } from "resources/AudiotekaApi";
import { LOTTERY_WAITING_FOR_CODES_ITEM } from "../consts";

const WaitingForCodesModalFull = dynamic(() => import("./waiting-for-codes-modal"), {
  ssr: false,
});

export function WaitingForCodesModal() {
  const user = useUser();
  const locale = useLocale();

  if (locale !== "pl") return null;
  if (!user.isLoggedIn) return null;

  const [lottery] = (user.hal.toObject() as User)._embedded?.["app:lotteries"] || [];
  if (
    !lottery ||
    (lottery.user_status !== "user_has_trial_but_codes_are_not_acquired_yet" &&
      lottery.user_status !== "user_has_subscription_but_codes_are_not_acquired_yet")
  ) {
    return null;
  }

  const wasSeenPreviously = localStorage.getItem(LOTTERY_WAITING_FOR_CODES_ITEM);

  if (!wasSeenPreviously) return <WaitingForCodesModalFull />;

  return null;
}
