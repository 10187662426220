import React from "react";
import classNames from "classnames";
import { useTranslations } from "next-intl";

import { Product } from "resources/AudiotekaApi";

import { usePrice } from "./usePrice";
import css from "./footer-bottom.module.scss";

interface Props {
  colorless?: boolean;
  noPrice?: boolean;
  product: Product;
}

const FooterBottomContent = ({ colorless, product, noPrice }: Props) => {
  const { isClubMember, isFreeInClub, cycle, shelf, basePrice, isClubPrice, finalPrice, lowestPrice } = usePrice(
    product,
    noPrice
  );

  const t = useTranslations();

  if (noPrice) return null;

  if (shelf || cycle) return null;

  if (finalPrice && !(isFreeInClub && isClubMember)) {
    return !isClubPrice && finalPrice !== basePrice && lowestPrice ? (
      <div className={classNames(css.lowestPrice, { [css.colorless]: colorless })}>
        {t("product.lowest_price", { price: lowestPrice.replace(/ /g, "\xa0") })}
      </div>
    ) : null;
  }

  return null;
};

export const FooterBottom = (props: Props) => (
  <div className={css.wrapper}>
    <FooterBottomContent {...props} />
  </div>
);
