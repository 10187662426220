import React from "react";

import Markdown from "components/markdown";
import { WebHeroCollectionSection } from "resources/AudiotekaApi";
import { deeplinkTranslator } from "modules/App/deeplinkTranslator";
import { ContainerBase } from "modules/DesignSystem/components/Container";
import { Link } from "ui/link";

import { trackCTAHomeEvent } from "../../home.utils";
import { Collection } from "./collection";
import css from "./web-hero-collection.module.scss";

export const WebHeroCollection = ({ section }: { section: WebHeroCollectionSection }) => {
  const [hero] = section.elements;

  return (
    <div className={css.webHeroCollection}>
      <img
        className={css.background}
        // eslint-disable-next-line react/no-unknown-property
        fetchPriority="high"
        src={`${hero.background_image_url}?auto=format`}
        alt=""
      />
      <div className={css.container}>
        <ContainerBase className={css.content} style={{ color: `#${hero.description_color}` }}>
          <Markdown text={hero.description} />
          {hero.button_label && hero.button_deeplink ? (
            <Link
              className={css.action}
              style={{ backgroundColor: `#${hero.button_color}`, color: `#${hero.button_label_color}` }}
              onClick={() => trackCTAHomeEvent("web_hero", hero.button_label)}
              {...deeplinkTranslator(hero.button_deeplink, hero.slug)}
            >
              {hero.button_label}
            </Link>
          ) : null}
        </ContainerBase>
        <Collection items={hero.collection} />
      </div>
    </div>
  );
};
