import React from "react";
import classNames from "classnames";

import { NewIcon } from "ui/NewIcon";
import { Product } from "resources/AudiotekaApi";

import css from "./footer-right.module.scss";
import { usePrice } from "./usePrice";

interface Props {
  colorless?: boolean;
  noPrice?: boolean;
  product: Product;
}

export const FooterRight = ({ colorless, product, noPrice }: Props) => {
  const { isClubMember, isFreeInClub, cycle, shelf, basePrice, isClubPrice, finalPrice } = usePrice(product, noPrice);

  if (shelf) {
    return (
      <NewIcon
        icon="shelf"
        width="24"
        height="24"
        className={classNames(css.priceIcon, { [css.colorless]: colorless })}
      />
    );
  }

  if (cycle) {
    return (
      <NewIcon
        icon="cycle"
        width="24"
        height="24"
        className={classNames(css.priceIcon, { [css.colorless]: colorless })}
      />
    );
  }

  if (noPrice) return null;

  if (finalPrice && !(isFreeInClub && isClubMember)) {
    return (
      <p
        className={classNames(css.price, { [css.colorless]: colorless })}
        data-base-price={!isClubPrice && basePrice !== finalPrice ? basePrice : undefined}
      >
        {finalPrice}
      </p>
    );
  }

  return null;
};
