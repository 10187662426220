import React from "react";
import dynamic from "next/dynamic";
import { useLocale } from "next-intl";

import { isServer } from "utils/runtime";
import { useUser } from "modules/user";

import { LOTTERY_INFO_ITEM, LOTTERY_START_TIME, LOTTERY_END_TIME, LOTTERY_TEST } from "../consts";

const LotteryInfoModalFull = dynamic(() => import("./lottery-info-modal"), {
  ssr: false,
});

export function isLoteryActive() {
  const now = Date.now();
  return now >= LOTTERY_START_TIME && now <= LOTTERY_END_TIME;
}

export function isLotteryTest() {
  if (isServer) return false;

  if (localStorage.getItem(LOTTERY_TEST) === "true") return true;

  const lotteryTest = new URL(window.location?.href).searchParams.get("lotteryTest") === "true";

  if (lotteryTest) {
    localStorage.setItem(LOTTERY_TEST, "true");
    return true;
  }

  return false;
}

export function LotteryInfoModal() {
  const user = useUser();
  const locale = useLocale();

  if (locale !== "pl") return null;
  if (!user) return null;
  if (user.isLoading || user.isClubMember) return null;
  if (!isLoteryActive() && !isLotteryTest()) return null;

  const count = localStorage.getItem(LOTTERY_INFO_ITEM);

  if (!count) return <LotteryInfoModalFull count={0} />;

  if (JSON.parse(count) >= 3) return null;

  return <LotteryInfoModalFull count={JSON.parse(count)} />;
}
