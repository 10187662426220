import React, { useEffect } from "react";
// eslint-disable-next-line import/no-unresolved
import { GoogleTagManager } from "@next/third-parties/google";
import { useRouter } from "next/router";
import Head from "next/head";
import Script from "next/script";
import { useLocale, useTranslations } from "next-intl";

import { Layout } from "layout";
import { getPathname } from "pathnames";
import { useUser } from "modules/user";
import SearchService from "modules/Search/SearchService";
import { getBreadcrumbs, getCategoryFromCatalog } from "modules/Audiobook/utils";
import { LotteryInfoModal } from "modules/global-modals/lottery/lottery-info-modal";
import { NewCodeInfoModal } from "modules/global-modals/lottery/new-code-info-modal";
import { WaitingForCodesModal } from "modules/global-modals/lottery/waiting-for-codes-modal";
import { getLocationInfo } from "utils/getLocationInfo";
import { Audiobook, Catalog, getAlgoliaSearchContext } from "resources/AudiotekaApi";

import { AppContainerProps } from "./App.types";
import { useSignInRedirectParams } from "./useSignInRedirectParams";
import { useOrganizationSchema } from "./useOrganizationSchema";

const getAudiobookProps = (catalog: Catalog, audiobook: Audiobook) => {
  const category = getCategoryFromCatalog(catalog, audiobook.main_category_id);
  return {
    breadcrumbs: category ? getBreadcrumbs({ category, catalog }) : [],
  };
};

const PageWrapper = ({
  Component,
  catalogItems,
  catalog,
  pageProps,
}: Pick<AppContainerProps, "Component" | "catalogItems" | "pageProps" | "catalog">) => {
  const { disableLayout = false, getLayout, catalogData } = Component.layoutConfig || {};

  if (disableLayout) {
    return <Component {...pageProps} />;
  }

  if (getLayout) {
    return <Layout catalogItems={catalogItems}>{getLayout(<Component {...pageProps} />)}</Layout>;
  }

  const props = catalogData ? { ...pageProps, ...getAudiobookProps(catalog, pageProps.audiobook) } : pageProps;

  return (
    <Layout catalogItems={catalogItems}>
      <Component {...props} />
    </Layout>
  );
};

export function AppContainer({ Component, pageProps = {}, disableRender, catalogItems, catalog }: AppContainerProps) {
  const { asPath, route } = useRouter();
  const t = useTranslations();
  const user = useUser();
  const locale = useLocale();
  const organizationSchema = useOrganizationSchema();

  const { setRedirectTo } = useSignInRedirectParams();

  if (disableRender) {
    return null;
  }

  const { gtmId } = getLocationInfo(locale);

  const { _canonical = `${asPath.replace(/\?(.*)$/, "")}`, _robots = "" } = pageProps;

  useEffect(() => {
    switch (route) {
      case getPathname("home").slice(0, -1):
        setRedirectTo(""); // Clear redirectTo on home
        break;
      case getPathname("signIn"):
      case getPathname("signUp"):
      case getPathname("passwordReset"):
      case getPathname("joinAudiotekaClub"):
        // Don't change redirectTo on auth pages
        break;
      default:
        setRedirectTo(`${asPath}`);
    }
  }, [asPath, route]);

  useEffect(() => {
    const fetchSearchIndexAndFilters = async () => {
      const { catalogId } = getLocationInfo(locale);

      const algolia = await getAlgoliaSearchContext(catalogId);

      SearchService.setConfig(algolia);
    };

    fetchSearchIndexAndFilters();
  }, []);

  return (
    <>
      <Head>
        <title>{t("meta.home.title")}</title>
        <meta name="robots" content={`${_robots && `${_robots}, `}max-image-preview:large`} />
        <meta name="description" content={t("meta.home.description")} />
        <meta key="og-title" property="og:title" content={t("meta.home.title")} />
        <meta key="og-description" property="og:description" content={t("meta.home.description")} />
        <meta
          key="og-image"
          property="og:image"
          content="https://fn.audioteka.com/pl/go/ogimage.jpg?locale=pl&v=2020-12-15"
        />
        <link key="canonical" rel="canonical" href={`${process.env.SITE_URL}${_canonical}`} />
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(organizationSchema) }} />
      </Head>

      <PageWrapper Component={Component} pageProps={pageProps} catalogItems={catalogItems} catalog={catalog} />
      <LotteryInfoModal />
      <NewCodeInfoModal />
      <WaitingForCodesModal />

      <Script id="wpjslib-config">
        {`
          var wp_onepager = true;
          var wp_sn = 'audioteka';
          var wp_consent_color = '#33ADFF';
          var wp_consent_text = '${t.markup("wp-consent-text", {
            b: (chunks) => `<strong>${chunks}</strong>`,
          })}<br><br>';
          var wp_consent_logo = 'https://atkcdn.com/audioteka/global/logo-transparent.png';
          var wp_consent_blur = 0;
          window.wp_cookie_info = false;
        `}
      </Script>
      <Script async crossOrigin="" noModule defer src="https://std.wpcdn.pl/wpjslib/wpjslib-lite.js" id="wpjslib" />
      <Script async crossOrigin="" type="module" src="https://std.wpcdn.pl/wpjslib6/wpjslib-lite.js" id="wpjslib6" />
      {gtmId && !user.isLoading && <GoogleTagManager gtmId={gtmId} />}
    </>
  );
}
