import React from "react";
import classNames from "classnames";

import { NewIcon } from "ui/NewIcon";
import { usePlayer } from "modules/player";

import css from "./action-button.module.scss";

interface Props {
  className?: string;
  slug: string;
  isPlayable: boolean;
  action:
    | "play"
    | {
        hoverIcon: "heart-border";
        icon: "heart";
        onClick(): void;
      };
}

const PlayButton = ({ className, slug }: Pick<Props, "className" | "slug">) => {
  const player = usePlayer();

  return (
    <button
      className={classNames(css.actionBtn, className)}
      onClick={() => player.load(slug, { autoPlay: true })}
      type="button"
    >
      <NewIcon icon="play" width="20" height="20" />
    </button>
  );
};

export const ActionButton = ({ action, className, isPlayable, slug }: Props) => {
  if (action === "play") {
    if (!isPlayable) {
      return null;
    }

    return <PlayButton className={className} slug={slug} />;
  }

  return (
    <button className={classNames(css.actionBtn, className)} onClick={action.onClick} type="button">
      <NewIcon icon={action.icon} width="20" height="20" />
      {action.hoverIcon && <NewIcon icon={action.hoverIcon} width="20" height="20" />}
    </button>
  );
};
