import React, { ComponentProps } from "react";
import gtm from "react-gtm-module";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";

import { useUser } from "modules/user/user.hooks";
import Modal from "modules/DesignSystem/components/modal";
import { Link } from "components";
import { useLaunchTestDomain } from "utils/useLaunchTestDomain";
import { getGTMProduct } from "utils/products";
import { getLicensesChannels } from "resources/AudiotekaApi";

import { useCart } from "../cart.hooks";
import { CartContext } from "../cart.types";
import { joinPriceWithCurrency } from "../cart.utils";
import {
  Actions,
  Button,
  Container,
  ItemAuthor,
  ItemCover,
  ItemPrice,
  ItemTitle,
  Root,
  Title,
  TitleIcon,
} from "./cart-modal.styled";

interface CartModalProps extends Pick<ComponentProps<typeof Modal>, "onClose"> {
  cartItem: CartContext["recentItem"];
}

export const CartModal = ({ cartItem, onClose: closeModal }: CartModalProps) => {
  const { product, price } = cartItem || {};

  const t = useTranslations();
  const { currency } = useCart();
  const user = useUser();
  const {
    query: { locale },
  } = useRouter();
  const launchTestDomain = useLaunchTestDomain();

  const handleBackToStore = () => {
    gtm.dataLayer({
      dataLayer: {
        event: "basket_info_modal",
        basket_info_modal_tab: "continue shopping",
      },
    });

    closeModal();
  };

  const handleGoToCart = async () => {
    gtm.dataLayer({
      dataLayer: {
        event: "basket_info_modal",
        basket_info_modal_tab: "go to cart",
      },
    });

    const licenses = user.isLoggedIn ? await getLicensesChannels(product.id) : null;

    gtm.dataLayer({
      dataLayer: {
        event: "view_cart",
        ecommerce: {
          currency,
          value: price / 100,
          items: [
            getGTMProduct({
              audiobook: product,
              isClubMember: user.isClubMember,
              licenses,
              product,
            }),
          ],
        },
      },
    });

    closeModal();
  };

  return (
    <Modal open={!!product} onClose={closeModal}>
      {product ? (
        <Root>
          <Title>
            <TitleIcon icon="check" width="20" height="16" />
            {t("cart.recent_item.title")}
          </Title>
          <Container>
            <ItemCover src={product.image_url} alt={product.name} />
            <ItemTitle>{product.name}</ItemTitle>
            <ItemAuthor>{product._embedded["app:author"].map((a) => a.name).join(", ")}</ItemAuthor>
            <ItemPrice>{joinPriceWithCurrency(price / 100, currency, locale as string)}</ItemPrice>
          </Container>
          <Actions>
            <Button onClick={handleBackToStore}>{t("cart.recent_item.back_to_store")}</Button>
            <Button
              as={Link as any}
              href={`${launchTestDomain}/[lang]/cart`}
              onClick={handleGoToCart}
              primary
              target="_blank"
            >
              {t("cart.go_to_cart")}
            </Button>
          </Actions>
        </Root>
      ) : null}
    </Modal>
  );
};
