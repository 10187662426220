import { useUser } from "modules/user";
import { Product } from "resources/AudiotekaApi";

export const usePrice = (product: Product, noPrice?: boolean) => {
  const { isClubMember } = useUser();

  const {
    deeplink: link,
    is_available_in_subscription: isFreeInClub,
    price_for_subscribers: clubPrice,
    price: basePrice,
    is_free: isFree,
    discount_price: promoPrice,
    lowest_price: lowestPrice,
  } = product;

  const isClubPrice = isClubMember && !!clubPrice;
  const finalPrice = !noPrice && !isFree && basePrice ? (isClubPrice ? clubPrice : promoPrice) ?? basePrice : null;

  return {
    isClubMember,
    isFreeInClub,
    cycle: /\/cycle\//.test(link),
    shelf: false,
    basePrice,
    isClubPrice,
    finalPrice,
    lowestPrice,
  };
};
