/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";
import { useTranslations } from "next-intl";

import { Link } from "ui/link";
import { NewIcon } from "ui/NewIcon";
import { ButtonPrimitive } from "ui/button/button-primitive";
import { useUser } from "modules/user";

import { userMenuItems } from "../consts";
import css from "./dropdown.module.scss";

const Dropdown = () => {
  const [open, setOpen] = useState<boolean>(false);
  const t = useTranslations();

  const { email: userEmail, signOut } = useUser();

  return (
    <div className={css.dropdown}>
      {!open ? (
        <ButtonPrimitive onClick={() => setOpen(true)}>
          <NewIcon icon="user" width="24" height="24" />
        </ButtonPrimitive>
      ) : (
        <>
          <div className={css.backdrop} onClick={() => setOpen(false)} />
          <ButtonPrimitive onClick={() => setOpen(false)}>
            <NewIcon icon="close" width="24" height="24" />
          </ButtonPrimitive>
          <div className={css.list} onClick={() => setOpen(false)}>
            {userMenuItems.map(({ title, name, icon, ...linkProps }) => (
              <Link className={css.item} key={name} title={t(title)} {...linkProps}>
                <NewIcon className={css.icon} icon={icon} width="28" height="28" />
                <div className={css.itemContent}>
                  <span>{t(name)}</span>
                </div>
              </Link>
            ))}
            <ButtonPrimitive className={css.item} title={t("navigation.logout")} onClick={signOut}>
              <NewIcon className={css.icon} icon="logout" width="28" height="28" />
              <div className={css.itemContent}>
                <span>{t("navigation.logout")}</span>
                <span className={css.description}>{userEmail}</span>
              </div>
            </ButtonPrimitive>
          </div>
        </>
      )}
    </div>
  );
};

export default Dropdown;
