import React from "react";
import dynamic from "next/dynamic";
import { useLocale } from "next-intl";

import { useUser } from "modules/user";
import { User } from "resources/AudiotekaApi";

import { LOTTERY_NEW_CODE_IDS_ITEM } from "../consts";

const NewCodeInfoModalFull = dynamic(() => import("./new-code-info-modal"), {
  ssr: false,
});

export function NewCodeInfoModal() {
  const user = useUser();
  const locale = useLocale();

  if (locale !== "pl") return null;
  if (!user.isLoggedIn) return null;

  const [lottery] = (user.hal.toObject() as User)._embedded?.["app:lotteries"] || [];
  if (!lottery || lottery.user_status !== "user_has_codes") return null;

  const codeIds = localStorage.getItem(LOTTERY_NEW_CODE_IDS_ITEM);
  if (!codeIds) return <NewCodeInfoModalFull lastCodeId={lottery.last_code_id} />;

  const parsedCodeIds = JSON.parse(codeIds);
  if (parsedCodeIds.includes(lottery.last_code_id)) return null;

  return <NewCodeInfoModalFull lastCodeId={lottery.last_code_id} shownCodeIds={parsedCodeIds} />;
}
